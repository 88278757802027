import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CodeGenerator from "./CodeGenerator";
import AddUserInformation from "./AddUserInformation";
import AddAppartmentsToUser from "./AddAppartmentsToUser";
import UserCreationContextProvider from "./UserCreationContextProvider";
import AddUsergroupsToNewUser from "./AddUsergroupsToNewUser";
import UserCreationContext from "./UserCreationContext";
import NewUserSummary from "./NewUserSummary";
import { Check } from "@mui/icons-material";
import APIWrapperContext from "../../../../services/APIWrapperContext";
import { AuthService, UserService } from "../../../../services/openapi";
const steps = [
  { name: "User Code generieren", label: "code_generate", isOwner: undefined },
  {
    name: "User Details anlegen",
    label: "add_user_details",
    isOwner: undefined,
  },
  { name: "Wohnungen zuweisen", label: "assign_appartments", isOwner: true },
  {
    name: "Usergruppen hinzufügen",
    label: "assign_usergroups",
    isOwner: false,
  },
  { name: "Zusammenfassung", label: "summary", isOwner: undefined },
];

export default function ConfigureNewUser() {
  const { isOwner, code, resetUserPreConf, buildEnvelope } =
    React.useContext(UserCreationContext);
  const { call_backend_api } = React.useContext(APIWrapperContext);
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});

  const totalSteps = () => {
    return steps.filter((s) => s.isOwner === undefined || s.isOwner === isOwner)
      .length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps
            .filter((s) => s.isOwner === undefined || s.isOwner === isOwner)
            .findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleSave = () => {
    console.log(buildEnvelope());

    call_backend_api(
      AuthService.addInfoToRegistrationCodeAuthRegistrationGenerateCodeAdditionalInfosPost(
        code,
        buildEnvelope()
      ),
      (t:any)=>{handleComplete()},
      undefined,
      undefined,
      true,
      "Zusatzinfos gespeichert!",
      "Fehler beim Speichern der Zusatzinfos!"
    );
  };

  const handleReset = () => {
    resetUserPreConf();
    setActiveStep(0);
    setCompleted({});
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper nonLinear activeStep={activeStep} alternativeLabel>
        {steps
          .filter((s) => s.isOwner === undefined || s.isOwner === isOwner)
          .map((label, index) => (
            <Step key={label.name} completed={completed[index]}>
              <StepButton
                color="inherit" //onClick={handleStep(index)}
              >
                {label.name}
              </StepButton>
            </Step>
          ))}
      </Stepper>
      <div>
        {allStepsCompleted() ? (
          <React.Fragment>
            <Box sx={{ textAlign: "center" }}>
              <Check sx={{ fontSize: "10rem" }} color="success" />
              <Typography sx={{ mt: 2, mb: 1 }}>
                Usercode wurde erfolgreich generiert!
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Neuen Usercode anlegen</Button>
              </Box>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Box sx={{ display: "flex", flexDirection: "row", mt: 2 }}>
              <Box sx={{ flex: 1 }} />
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {steps.filter(
                  (s) => s.isOwner === undefined || s.isOwner === isOwner
                )[activeStep].label === "code_generate" && <CodeGenerator />}
                {steps.filter(
                  (s) => s.isOwner === undefined || s.isOwner === isOwner
                )[activeStep].label === "add_user_details" && (
                  <AddUserInformation />
                )}
                {steps.filter(
                  (s) => s.isOwner === undefined || s.isOwner === isOwner
                )[activeStep].label === "assign_appartments" && (
                  <AddAppartmentsToUser />
                )}
                {steps.filter(
                  (s) => s.isOwner === undefined || s.isOwner === isOwner
                )[activeStep].label === "assign_usergroups" && (
                  <AddUsergroupsToNewUser />
                )}
                {steps.filter(
                  (s) => s.isOwner === undefined || s.isOwner === isOwner
                )[activeStep].label === "summary" && <NewUserSummary />}
              </Box>
              <Box sx={{ flex: 1 }} />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Zurück
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              {/* <Button onClick={handleNext} sx={{ mr: 1 }}>
                Next
              </Button> */}
              {activeStep !== steps.length && (
                // (completed[activeStep] ? (
                //   <Typography variant="caption" sx={{ display: 'inline-block' }}>
                //     Step {activeStep + 1} already completed
                //   </Typography>
                // ) :
                <Button
                  onClick={(e) => {
                    if (completedSteps() === totalSteps() - 1 && isLastStep()) {
                      handleSave();
                    } else {
                      handleComplete();
                    }
                  }}
                  disabled={code == undefined || code == ""}
                  title={
                    code == undefined || code == ""
                      ? "Bitte zuerst einen Code generieren"
                      : ""
                  }
                >
                  {completedSteps() === totalSteps() - 1 && isLastStep()
                    ? "Speichern"
                    : "Weiter"}
                </Button>
              )}
            </Box>
          </React.Fragment>
        )}
      </div>
    </Box>
  );
}
