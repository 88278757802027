import * as React from 'react';
import { Box, Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Button, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { getRegistrationCodeForNewUser } from '../../../../BackendFunctions/backend_api_AuthService';
import { sendMailWithRegistrationCode } from '../../../../BackendFunctions/backend_api_AuthService';
import NutzerListe from './UserList';
import { ipaddress } from '../../../../BackendFunctions/ipconfig';
import { getHouses } from '../../../../BackendFunctions/backend_api_HausverwaltungService';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { preConfigUser } from '../../../../BackendFunctions/backend_api_UserService';

import { AuthService, HausverwaltungService } from '../../../../services/openapi';
import { SyntheticEvent } from 'react';
import APIWrapperContext from '../../../../services/APIWrapperContext';
import { WidgetEmailEditor } from '../../../Widgets/EmailEditor/WidgetEmailEditor';
import NewUser from './NewUser';

interface ITabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props:ITabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {/* <Typography> */}
          {children}
          {/* </Typography> */}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index:number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface Ihaus{
    house:string,
    number:string
    appartments:Ihaus[]
}

export default function Nutzerverwaltung() {
  // const { token, sessionStart } = React.useContext(SecurityContext)
  const [secondary, setSecondary] = React.useState(false);
  const [code, setCode] = React.useState("");
  const [emailReceiver, setEmailReceiver] = React.useState("");
  const [emailMessage, setEmailMessage] = React.useState("");
  const [showSendMail, setShowSendMail] = React.useState(false);
  const [showPreConfigUser, setShowPreConfigUser] = React.useState(false);
  const [loadUsersUpdateFct, setLoadUsersUpdateFct] = React.useState(() => { });
  const [hauslist, set_hauslist] = React.useState<Ihaus[]>([]);
  const [hauslist_preConfUserAccess, set_hauslist_preConfUserAccess] = React.useState<Ihaus[]>([]);
  let theme = useTheme();
  const {call_backend_api} = React.useContext(APIWrapperContext)


  const [value, setValue] = React.useState(0);

  const addAppartment_PreConfUserAccess = (appartment:Ihaus) => {
    let groupList_tmp = [...hauslist_preConfUserAccess]

    groupList_tmp.push(appartment)
    set_hauslist_preConfUserAccess(groupList_tmp)

  }
  const removeAppartment_PreConfUserAccess = (appartment:Ihaus) => {
    let usersGroups_tmp = [...hauslist_preConfUserAccess]
    usersGroups_tmp = usersGroups_tmp.filter(g => !(g.house == appartment.house && g.number == appartment.number))

    set_hauslist_preConfUserAccess(usersGroups_tmp)
  }


  React.useEffect(() => {
    console.log("Userfct loaded")
    console.log(loadUsersUpdateFct)
  }, [loadUsersUpdateFct])

  React.useEffect(() => {
    const message = `<html>
      Sehr geehrte Frau ... / Sehr geehrter Herr ...,
      <br><br>
      mit dieser E-Mail senden wir Ihnen den Registrierungs-Code für unsere digitale Dokumentenablage.
      <br>
      Bitte verwenden Sie den Code für die Registrierung unter <a href="https://app.boehringer.net/register">app.boehringer.net/register</a>.
      <br>
      Ihr persönlicher Code: ` + code +
      `<br><br>Vielen Grüße,<br><br>ihr Team von Böhringer.
      <html>`;

    setEmailMessage(message)
    setShowSendMail(false)
    setShowPreConfigUser(false)
    setEmailReceiver("")
  }, [code])

  function fetchCode(url = '', data = {}) {

    url = "" + ipaddress + "/registration/generate_code";
    fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        //'Authorization': 'Bearer ' + token,
        'content-type': 'application/json'
      }
    })
      .then(response => response.json())





  }
  interface Ihouse{
    house:string,
    appartmentname:string
  }

  const handleConfigUser = () => {
    let houseList:Ihouse[] = []
    hauslist_preConfUserAccess.forEach(el => {
      houseList.push(
        {
          "house": el.house,
          "appartmentname": el.number
        }
      );

    })
    if (code.length > 0) {
      preConfigUser(code, houseList)
    }

  }

  const getHausliste = () => {
    call_backend_api(HausverwaltungService.listHousesHausverwaltungHousesListGet(),(t:Ihaus[])=>set_hauslist(t),undefined,undefined,false)
    //getHouses().then(t => { set_hauslist(t) })

  }

  const handleSendEmailToReceiver = (event:React.MouseEvent<HTMLElement>) => {
    call_backend_api(AuthService.sendRegistrationMailAuthRegistrationSendRegistrationMailPost({emailReceiver:emailReceiver, message:emailMessage}),()=>{
        setCode("")
      setShowSendMail(false)
      setShowPreConfigUser(false)
      setEmailMessage("")
      setEmailReceiver("")
    },undefined,undefined,true,"E-mail wurde versendet","Fehler beim Senden der Email!")
       
    
  }

  const handleShowSendMailButton = (event:React.MouseEvent<HTMLElement>) => {
    setShowSendMail(true)
  }
  const handleShowPreConfigUserButton = (event:React.MouseEvent<HTMLElement>) => {
    getHausliste()
    setShowPreConfigUser(true)
  }

  const handleEmailReceiverChange = (event:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setEmailReceiver(event.target.value)
  }
  const handleEmailMessageChange = (event:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    console.log(event.target.value)
    setEmailMessage(event.target.value)
  }

  const handleChange = (event:SyntheticEvent<Element, Event>, newValue:number) => {
    setValue(newValue);
  };
  interface IGeneratedCode{
    code:string
    status:boolean
  }
  const handleCreateCode = () => {
    call_backend_api(
      AuthService.getNewRegistrationCodeAuthRegistrationGenerateCodeGet(),
      (t:IGeneratedCode)=>setCode(t.code),
      undefined,undefined,true,"Code generiert","Fehler beim Generieren des Codes!")
    
    
  }

  return (
    <>
      <Container component="main" >
      <Typography sx={{ maxWidth: '100%', typography: { xs: 'h4', md: 'h3' } }}>Nutzerverwaltung</Typography>

        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" scrollButtons variant='scrollable' allowScrollButtonsMobile sx={{ width: "100%" }}>
              <Tab label="Nutzerverwaltung" {...a11yProps(0)} />
              <Tab label="Nutzer-Code" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={1}>
            <NewUser/>
          </TabPanel>
          <TabPanel value={value} index={0}>
            <Box>
              <NutzerListe  ></NutzerListe>
            </Box>
          </TabPanel>

        </Box>


      </Container>

    </>
  );
}





