import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { IAdvertisement } from "./BasicTableWithPagination";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { OpenAPI } from "../../../services/openapi";

export interface IEditAd {
  open: boolean;
  setOpen: (ad: IAdvertisement | undefined,file:Blob|undefined) => void;
  ad: IAdvertisement | undefined;
}

export default function EditAd(props: IEditAd) {
  const { open, setOpen, ad } = props;

  const [tmpAd, setTmpAd] = React.useState<IAdvertisement | undefined>(ad);
  React.useEffect(() => {
    setTmpAd(ad);
  }, [ad]);

  // id: number,
  // link: string,
  // img_url: string,
  // description: string,
  // location: string,
  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value, checked } = event.target;
    console.log("Changed Value", id, value, checked);
    if (tmpAd !== undefined) {
      setTmpAd({ ...tmpAd, [id]: id !== "visible" ? value : checked });
    }
  };
  const [selectedFiles, setSelectedFiles] = React.useState<File | undefined>(
    undefined
  );

  const handleClose = () => {
    setOpen(undefined,undefined);
  };
  const handleSaveAndClose = () => {
    setOpen(tmpAd !== undefined ? tmpAd : undefined,selectedFiles);
  };

  const AcceptedFileType = {
    Text: ".txt",
    Gif: ".gif",
    Jpeg: ".jpg",
    Png: ".png",
    Doc: ".doc",
    Pdf: ".pdf",
    AllImages: "image/*",
    AllVideos: "video/*",
    AllAudios: "audio/*",
  };

  const fileRef = React.useRef<HTMLInputElement>(null);
  // const acceptedFormats =
  //   typeof fileType === 'string'
  //     ? fileType
  //     : Array.isArray(fileType)
  //     ? fileType?.join(',')
  //     : AcceptedFileType.Text;


  React.useEffect(() => {
    if (selectedFiles !== undefined) {
      const reader = new FileReader();
      reader.onloadend = function (e) {
        if (tmpAd !== undefined) {
          setTmpAd({ ...tmpAd, img_url: reader.result as string });
        }
      };
      reader.readAsDataURL(selectedFiles);
    }
  }, [selectedFiles])
  const handleFileSelect = (event: any) => {
    console.log(event?.target?.files?.[0]);
    setSelectedFiles(event?.target?.files?.[0]);
  };

  const onUpload = () => {
    console.log(selectedFiles);
  };

  const onClear = () => {
    setSelectedFiles(undefined);
  };

  const onUpdate = (event: any) => {
    if (event.target.textContent.trim().toLowerCase() === "change") {
      onClear();
      if (fileRef.current !== null) {
        fileRef.current.click();
      }
      return;
    }
    if (event.target.textContent.trim().toLowerCase() === "clear") {
      onClear();
      return;
    }
  };
  const reader = new FileReader();
  const [selectedFile, setSelectedFile] = React.useState(null);
  const handleUploadClick = (
    event: React.ChangeEventHandler<HTMLInputElement>
  ) => {
    //var file = event.target.files[0];
    const reader = new FileReader();
    //var url = reader.readAsDataURL(file);

    reader.onloadend = function (e) {
      //setSelectedFile([reader.result]);
    };
    //console.log(url); // Would see a path?

    // this.setState({
    //   mainState: "uploaded",
    //   selectedFile: event.target.files[0],
    //   imageUploaded: 1
    // });
  };

  return (
    <Dialog
      open={open}
      //onClose={handleClose}
      fullWidth={true}
      maxWidth={"lg"}
    >
      <DialogTitle>Werbung bearbeiten</DialogTitle>
      <DialogContent>
        {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We
            will send updates occasionally.
          </DialogContentText> */}
        <FormControlLabel
          label="Sichtbarkeit"
          control={
            <Checkbox
              checked={tmpAd !== undefined ? tmpAd.visible : false}
              id="visible"
              onChange={handleValueChange}
            />
          }
        />

        <TextField
          autoFocus
          margin="dense"
          id="description"
          label="Name"
          type="text"
          fullWidth
          variant="standard"
          value={tmpAd !== undefined ? tmpAd.description : ""}
          onChange={handleValueChange}
        />
        <TextField
          autoFocus
          margin="dense"
          id="location"
          label="Ortschaft"
          type="text"
          fullWidth
          variant="standard"
          value={tmpAd !== undefined ? tmpAd.location : ""}
          onChange={handleValueChange}
        />
        <TextField
          autoFocus
          margin="dense"
          id="status"
          label="Status"
          type="text"
          fullWidth
          variant="standard"
          value={tmpAd !== undefined ? tmpAd.status : ""}
          onChange={handleValueChange}
        />
        <TextField
          autoFocus
          margin="dense"
          id="link"
          label="Info-Link"
          type="url"
          fullWidth
          variant="standard"
          value={tmpAd !== undefined ? tmpAd.link : ""}
          onChange={handleValueChange}
        />
        <Box sx={{display:'flex',flexDirection:'row'}}>
            <Box sx={{flex:1}}></Box>
        <Box
          component="img"
          sx={{
            flex: 1,
            objectFit: "contain",
            height: "100%",
            maxHeight:"200px",
            width: "100%",
            maxWidth: "sm",
            align: "center",
          }}
          src={tmpAd !== undefined ? (tmpAd.img_url.includes("data:image")?tmpAd.img_url:("" + OpenAPI.BASE + "/ads/image?image_id="+tmpAd.img_url )): ""}
          alt="Live from space album cover"
        />
        <Box sx={{flex:1}}></Box>
        </Box>
        <Box  sx={{display:'flex',flexDirection:'row', mt:1}}>
        <Box sx={{flex:1}}></Box>
        <input
          ref={fileRef}
          hidden
          type="file"
          accept={"image/*"}
          onChange={handleFileSelect}
        />
        {selectedFiles ? selectedFiles.name : ""}
        

        <Button
          variant="contained"
          component="label"
          style={{ textTransform: "none" }}
          onClick={() => {
            fileRef.current && (fileRef.current as any).click();
          }}
        >
          Bild ändern
        </Button>
        <Box sx={{flex:1}}></Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Abbrechen</Button>
        <Button onClick={handleSaveAndClose} variant="contained">
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  );
}
