import * as React from "react";
import {} from "./types";
import { IAppartment, IAppartmentInfos, IHouse, IUser } from "./interfaces";
import { DialogContentText, Menu, MenuItem, TextField } from "@mui/material";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ApartmentIcon from "@mui/icons-material/Apartment";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import { Divider, IconButton, ListItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { getUsers } from "../../../../BackendFunctions/backend_api_UserService";


import DialogRawNewHouse from "./DialogWindowNewHouse";
import DisAgreeDialogRaw from "./DialogWindowDisAgree";
import DialogRawNewAppartment from "./DialogWindowNewAppartment";
import DialogRawEditAppartment from "./DialogWindowEditAppartment";
import { Interface } from "readline";
import APIWrapperContext from "../../../../services/APIWrapperContext";
import { HausverwaltungService } from "../../../../services/openapi";
import { UserService } from "../../../../services/openapi";
import SpeedDialOptions from "./SpeedDialOptions";
import HausUndAppartmentListe from "./HausUndAppartmentListe";
import MobileMenu from "./MobileMenu";
import FormDialog from "./FormDialog";
import {FormDialogNewHouse, FormDialogEditHouse} from "./Hausliste_editHouse";
import { FormDialogNewAppartment } from "./Hausliste_editAppartment";
const actions = [
  { icon: <ApartmentIcon />, name: "Haus hinzufügen" },
  { icon: <MeetingRoomIcon />, name: "Wohnung / Zimmer hinzufügen" },
  // { icon: <PrintIcon />, name: 'Print' },
  // { icon: <ShareIcon />, name: 'Share' },
];

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
    border: "1px solid black",
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

type OpenHouses = {
  [key: string]: boolean;
};

interface IOpenDialogs {
  openDialog_NewHouse: boolean;
  openDialog_EditHouse: boolean;
  openDialog_DeleteHouse: boolean;
  openDialog_NewAppartment: boolean;
  openDialog_EditAppartment: boolean;
  openDialog_DeleteAppartment: boolean;
}

export default function Hausliste() {
  const { call_backend_api } = React.useContext(APIWrapperContext);
  const [open, setOpen] = React.useState<OpenHouses>({});
  const [openDialogs, setOpenDialogs] = React.useState<IOpenDialogs>({
    openDialog_NewHouse: false,
    openDialog_EditHouse: false,
    openDialog_DeleteHouse: false,
    openDialog_NewAppartment: false,
    openDialog_EditAppartment: false,
    openDialog_DeleteAppartment: false,
  });
  const openDialog = (dialogName: ("openDialog_NewHouse"|"openDialog_EditHouse"|"openDialog_DeleteHouse"|"openDialog_NewAppartment"|"openDialog_EditAppartment"|"openDialog_DeleteAppartment")) => {
    let temp_openDialogs = {...openDialogs}
    temp_openDialogs[dialogName]=true
    setOpenDialogs(temp_openDialogs)
  }
  const closeDialog = (dialogName: ("openDialog_NewHouse"|"openDialog_EditHouse"|"openDialog_DeleteHouse"|"openDialog_NewAppartment"|"openDialog_EditAppartment"|"openDialog_DeleteAppartment")) => {
    let temp_openDialogs = {...openDialogs}
    temp_openDialogs[dialogName]=false
    setOpenDialogs(temp_openDialogs)
  }

  const [temp_hauslist, set_temp_hauslist] = React.useState<Array<IHouse>>([]);
  const [hauslist, set_hauslist] = React.useState<Array<IHouse>>([]);
  const [selectedHouse, setSelectedHouse] = React.useState<IHouse | undefined>(
    undefined
  );
  const [selectedAppartment, setSelectedAppartment] = React.useState<
    IAppartment | undefined
  >(undefined);
  const [users, setUsers] = React.useState<Array<IUser>>([]);
  const [appartmentInfos, setAppartmentInfos] =
    React.useState<IAppartmentInfos>();

  const handleClick = (hausname: string) => {
    let temp_open = { ...open };
    temp_open[hausname] = !temp_open[hausname];
    setOpen(temp_open);
  };
  const updateHouseList = () => {
    call_backend_api(
        HausverwaltungService.listHousesHausverwaltungHousesListGet(),
        (t: Array<IHouse>) => {
          set_hauslist(t);
        },
        undefined,
        undefined,
        false
      );
  }
  React.useEffect(() => {
    updateHouseList()
    call_backend_api(
      UserService.listUsersUserListGet(),
      (t: Array<IUser>) => {
        setUsers(t);
      },
      undefined,
      undefined,
      false
    );
    // getHouses("token").then(t => { set_hauslist(t) })
    // getUsers("token").then(t=>setUsers(t))
  }, []);
  React.useEffect(() => {
    set_temp_hauslist(JSON.parse(JSON.stringify(hauslist)));
  }, [hauslist]);
  const handleSearch = (value: string) => {
    //temp_hauslist = JSON.parse(JSON.stringify(hauslist));
    set_temp_hauslist(hauslist.filter((el) => el.name.includes(value)));
    //temp_hauslist=temp_hauslist.filter(el=>el.name.includes(value));
  };

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<
    null | (EventTarget & HTMLButtonElement)
  >(null);
  const [mobileMoreAnchorEl_Appartment, setMobileMoreAnchorEl_Appartment] =
    React.useState<null | (EventTarget & HTMLButtonElement)>(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isMobileMenuOpen_Appartment = Boolean(mobileMoreAnchorEl_Appartment);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
    setSelectedHouse(undefined);
  };

 

  const handleMobileMenuOpen = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value: IHouse
  ) => {
    setMobileMoreAnchorEl(event.currentTarget);
    // console.log("Selected House",value)
    setSelectedHouse(value);
  };
  const handleEditHouse = () => {
    openDialog("openDialog_EditHouse")
    // setOpenDialog_NewHouse(true);
  };
  const handleNewAppartment = () => {
    openDialog("openDialog_NewAppartment")
    // setOpenDialog_NewAppartment(true);
  };
  const handleEditAppartment = () => {
    openDialog("openDialog_EditAppartment")
    // setOpenDialog_NewAppartment(true);
  };
  const handleDeleteAppartment = () => {
    openDialog("openDialog_DeleteAppartment")
    // setOpenDialog_DeleteAppartment(true);
  };
  const handleCloseEditAppartment = (value: IAppartment | undefined) => {
    if (value) {
      if (!selectedAppartment || !selectedAppartment.id) {
        // addAppartment("token", value.name, value.house);
        console.log("creating new appartment",value)
        call_backend_api(HausverwaltungService.createAppartmentHausverwaltungAppartmentsCreatePost(value.name,value.house),()=>{updateHouseList()},undefined,undefined,true,"Neue Wohnung erstellt","Fehler beim Erstellen der Wohnung")
      } else {
        console.log("Values", value);
        call_backend_api(HausverwaltungService.updateAppartmentByNameHausverwaltungAppartmentsUpdatePut(selectedAppartment.number,
            selectedAppartment.house,
            "",
            value.name,
            value.house,
            {
              owners:
                value.owners !== undefined
                  ? value.owners.map((el) => el.email)
                  : [],
              renters: value.renters ? value.renters.map((el) => el.email) : [],
            }),undefined,undefined,undefined,true)

        
      }
    }
    closeDialog("openDialog_NewAppartment")
    closeDialog("openDialog_EditAppartment")
    // setOpenDialog_NewAppartment(false);
    handleMobileMenuClose_Appartment();
  };
  const handleClose_NewHouse = (values: FormData | undefined) => {
    if(values){
      const adresse = values.get("adresse");
      const new_house:IHouse = {
        name: adresse ? adresse.toString() : "",
        address: adresse ? adresse.toString() : "",
        id:0
      };
      handleCloseEditHouse(new_house)
    }
    closeDialog("openDialog_NewHouse")
    setMobileMoreAnchorEl(null)
  }
  const handleClose_EditHouse = (values: FormData | undefined) => {
    if(values){
      const adresse = values.get("adresse");
      const new_house:IHouse = {
        name: adresse ? adresse.toString() : "",
        address: adresse ? adresse.toString() : "",
        id:selectedHouse ? selectedHouse.id : -1
      };
      handleCloseEditHouse(new_house)
    }
    closeDialog("openDialog_EditHouse")
    setMobileMoreAnchorEl(null)
    
  }
  const handleCloseEditHouse = (value: IHouse | undefined) => {
    console.log("createHouse", value);
    if (value ) {
      if (value.id!==-1 && selectedHouse) {
        //updateHouse
        //address first
        if (value.address !== selectedHouse.address) {
          //updateHouse("token", selectedHouse.name, "address", value.address).then(getHouses("token").then(t => { set_hauslist(t) }))
          call_backend_api(HausverwaltungService.updateHouseByNameHausverwaltungHousesUpdatePut(selectedHouse.name, "address", value.address),()=>{updateHouseList()},undefined,undefined,true)
        }
        if (value.name !== selectedHouse.name) {
          // updateHouse("token", selectedHouse.name, "name", value.name).then(getHouses("token").then(t => { set_hauslist(t) }))
          call_backend_api(HausverwaltungService.updateHouseByNameHausverwaltungHousesUpdatePut(selectedHouse.name, "name", value.name),()=>{updateHouseList()},undefined,undefined,true)
        }
      } else {
        //addNewHouse
        console.log("creating new house");
        call_backend_api(HausverwaltungService.createHouseHausverwaltungHousesCreatePost(value.name,value.address),()=>{updateHouseList()},undefined,undefined,true)
        
      }
    }
    closeDialog("openDialog_NewHouse")
    
    handleMobileMenuClose();
  };
  const handleDeleteHouse = () => {
    openDialog("openDialog_DeleteHouse")
    
  };
  const handleCloseDeleteHouse = (value: IHouse | undefined) => {
    // setOpenDialog_DeleteHouse(false);
    closeDialog("openDialog_DeleteHouse")
    if (selectedHouse && value) {
      // deleteHouse("token", selectedHouse.name).then(getHouses("token").then(t => { set_hauslist(t) }))
      call_backend_api(HausverwaltungService.deleteHouseByNameHausverwaltungHousesDeleteDelete(selectedHouse.name),()=>{updateHouseList()},undefined,undefined,true)
    }
    handleMobileMenuClose();
  };

  const handleCloseNewAppartment = (values: FormData | undefined) => {
    if(values){
      const name = values.get("name");
      const house = values.get("house");
      const new_appartment:IAppartment = {
        name: name ? name.toString() : "",
        number: name ? name.toString() : "",
        house: house ? house.toString() : "",
        id:0
      };
      console.log("new_appartment",new_appartment)
      handleCloseEditAppartment(new_appartment)
    }
    closeDialog("openDialog_NewAppartment")
    setMobileMoreAnchorEl_Appartment(null)
  }

  const handleCloseDeleteAppartment = (value: IAppartment | undefined) => {
    closeDialog("openDialog_DeleteAppartment")
    // setOpenDialog_DeleteAppartment(false);
    if (selectedAppartment && value) {
      // deleteAppartment("token", selectedAppartment.number,selectedAppartment.house).then(getHouses("token").then(t => {set_hauslist(t)  }))
      call_backend_api(HausverwaltungService.deleteAppartmentByNameHausverwaltungAppartmentsDeleteGet(selectedAppartment.number,selectedAppartment.house),()=>{updateHouseList()},undefined,undefined,true)
    }
    handleMobileMenuClose_Appartment();
  };
  React.useEffect(() => {
    console.log(selectedAppartment);
    if (selectedAppartment) {
      //getAppartment("token",selectedAppartment.number,selectedAppartment.house).then(t=>setAppartmentInfos(t))
      call_backend_api(HausverwaltungService.getAppartmentsHausverwaltungAppartmentsGetAppartmentGet(selectedAppartment.number,selectedAppartment.house),(t:IAppartmentInfos)=>{setAppartmentInfos(t)},undefined,undefined,false)
    }
  }, [selectedAppartment]);

  

  
  const renderMobileMenu = (
    <>
    <MobileMenu
        mobileMoreAnchorEl={mobileMoreAnchorEl}
        open={isMobileMenuOpen}
        handleClose={handleMobileMenuClose}
        menuItems={[
          {
            icon: <EditIcon />,
            name: "Bearbeiten",
            onClickFunc: handleEditHouse,
          },
          {
            icon: <DeleteForeverIcon />,
            name: "Löschen",
            onClickFunc: handleDeleteHouse,
          },
        ]}
      ></MobileMenu>
    </>
  );
  const handleMobileMenuClose_Appartment = () => {
    setMobileMoreAnchorEl_Appartment(null);
    setSelectedAppartment(undefined);
  };

  const handleMenuCloseAndLogout_Appartment = () => {
    handleMobileMenuClose_Appartment();
  };

  const handleMobileMenuOpen_Appartment = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value: IAppartment
  ) => {
    setSelectedAppartment(value);
    console.log("Selected Appartment", value)
    setMobileMoreAnchorEl_Appartment(event.currentTarget);
  };

  
  const renderMobileMenu_Appartment = (
    <>
      <MobileMenu
        mobileMoreAnchorEl={mobileMoreAnchorEl_Appartment}
        open={isMobileMenuOpen_Appartment}
        handleClose={handleMobileMenuClose_Appartment}
        menuItems={[
          {
            icon: <EditIcon />,
            name: "Bearbeiten",
            onClickFunc: handleEditAppartment,
          },
          {
            icon: <DeleteForeverIcon />,
            name: "Löschen",
            onClickFunc: handleDeleteAppartment,
          },
        ]}
      ></MobileMenu>
    </>
  );

  return (
    <>
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search…"
          inputProps={{ "aria-label": "search" }}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </Search>

      <HausUndAppartmentListe
        hauslist={temp_hauslist}
        handleMobileMenuOpen={handleMobileMenuOpen}
        handleClick={handleClick}
        open={open}
        handleMobileMenuOpen_Appartment={handleMobileMenuOpen_Appartment}
      ></HausUndAppartmentListe>
      <SpeedDialOptions
        handleEditAppartment={handleNewAppartment}
        handleEditHouse={handleEditHouse}
      ></SpeedDialOptions>
      {renderMobileMenu}
      {renderMobileMenu_Appartment}
      {
        <FormDialogNewHouse
        open={openDialogs.openDialog_NewHouse}
        handleClose={handleClose_NewHouse}
        />
      }
      {
        <FormDialogEditHouse
        open={openDialogs.openDialog_EditHouse}
        handleClose={handleClose_EditHouse}
        currentAdresse={selectedHouse ? selectedHouse.name : ""}
        newHouse={selectedHouse?false:true}
        />
      }
      
      
      {selectedHouse && (
        <DisAgreeDialogRaw
          id="deletHouse"
          keepMounted
          open={openDialogs.openDialog_DeleteHouse}
          onClose={handleCloseDeleteHouse}
          action={"deleteHaus"}
          value={true}
          text={
            "Soll das Haus " +
            (selectedHouse && selectedHouse.name ? selectedHouse.name : "") +
            " wirklich mit allen Wohnungen / Zimmern gelöscht werden?"
          }
          title={"Haus löschen"}
        ></DisAgreeDialogRaw>
      )}
      {selectedAppartment && (
        <DisAgreeDialogRaw
          id="deletAppartment"
          keepMounted
          open={openDialogs.openDialog_DeleteAppartment}
          onClose={handleCloseDeleteAppartment}
          action={"deleteAppartment"}
          value={true}
          text={
            "Soll die Wohnung / das Zimmer " +
            (selectedAppartment && selectedAppartment.number
              ? selectedAppartment.number
              : "") +
            " wirklich gelöscht werden?"
          }
          title={"Wohnung / Zimmer löschen"}
        ></DisAgreeDialogRaw>
      )}
      {/* {!selectedAppartment && openDialogs.openDialog_NewAppartment && (
        <DialogRawNewAppartment
          id="addAppartment"
          keepMounted
          open={openDialogs.openDialog_NewAppartment}
          onClose={handleCloseEditAppartment}
          action={"createNewAppartment"}
          value={false}
          text={""}
          hausname={""}
          addresse={""}
          haus_id={undefined}
          title={"Neue Wohnung / Neues Zimmer hinzufügen"}
          houses={hauslist.map((h) => h.name)}
          confirmText={selectedAppartment ? "Übernehmen" : "Hinzufügen"}
        ></DialogRawNewAppartment>
      )} */}
        <FormDialogNewAppartment open={openDialogs.openDialog_NewAppartment} handleClose={handleCloseNewAppartment} houses={hauslist}/>
      {selectedAppartment && openDialogs.openDialog_EditAppartment && (
        // <FormDialogEditAppartment open={openDialogs.openDialog_NewAppartment} handleClose={handleCloseNewAppartment} houses={hauslist}/>

        
        <DialogRawEditAppartment
          // id="addAppartment2"
          // keepMounted
          open={openDialogs.openDialog_EditAppartment}
          onClose={handleCloseEditAppartment}
          // action={"createNewAppartment"}
          value={false}
          text={""}
          hausname={selectedAppartment.number}
          addresse={selectedAppartment.house}
          haus_id={selectedAppartment.id}
          title={"Wohnung / Zimmer bearbeiten"}
          houses={hauslist.map((h) => h.name)}
          // users={users}
          // owners={appartmentInfos ? appartmentInfos.owners : []}
          // renters={appartmentInfos ? appartmentInfos.renters : []}
          confirmText={selectedAppartment ? "Übernehmen" : "Hinzufügen"}
        ></DialogRawEditAppartment>
      )}
    </>
  );
}
