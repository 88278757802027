import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { IHouse } from "./interfaces";
import { MenuItem, Typography } from "@mui/material";
import PickingList from "./PickingList";
interface IFormDialog_NewAppartmentProps {
  open: boolean;
  handleClose: (values?: FormData) => void;
  houses: IHouse[];
}
// interface IFormDialog_EditHouseProps extends IFormDialog_NewAppartmentProps {
//     currentAdresse:string
// }

export function FormDialogNewAppartment(props: IFormDialog_NewAppartmentProps) {
  const { open, handleClose, houses } = props;
  // let houses:IHouse[] =[]

  return (
    // <React.Fragment>

    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
      onClose={() => {
        handleClose();
      }}
      PaperProps={{
        component: "form",
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          console.log(
            "new FormData(event.currentTarget)",
            new FormData(event.currentTarget)
          );
          handleClose(new FormData(event.currentTarget));
          // const formData = new FormData(event.currentTarget);
          // const formJson = Object.fromEntries((formData as any).entries());
          // const email = formJson.email;
          // console.log(email);
          handleClose();
        },
      }}
    >
      <DialogTitle>{"Neues Appartment erstellen"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {"Teststtstst"}
        </DialogContentText>
        <TextField
          // autoFocus
          margin="dense"
          id="name"
          name="name"
          label="Wohnungs- / Zimmername"
          fullWidth
          variant="standard"
          //defaultValue={"Test"}
        />

        <TextField
          id="house"
          name="house"
          select
          label="Haus"
          defaultValue={""}
          fullWidth
          // onChange={}
          helperText="Bitte tragen Sie ein bereits existierendes Haus hier ein."
          variant="standard"
        >
          {houses &&
            houses.map((option) => (
              <MenuItem key={option.name} value={option.name}>
                {option.name}
              </MenuItem>
            ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleClose();
          }}
        >
          Nein
        </Button>
        <Button type="submit" autoFocus>
          Erstellen
        </Button>
      </DialogActions>
    </Dialog>
    // </React.Fragment>
  );
}
// export function FormDialogEditAppartment(props: IFormDialog_EditHouseProps) {

// const {open,handleClose, currentAdresse} = props;

//   return (
//     <React.Fragment>

//       <Dialog
//       sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
//       maxWidth="xs"
//         open={open}
//         onClose={()=>{handleClose()}}
//         PaperProps={{
//           component: 'form',
//           onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
//             event.preventDefault();
//             handleClose(new FormData(event.currentTarget));
//             // const formData = new FormData(event.currentTarget);
//             // const formJson = Object.fromEntries((formData as any).entries());
//             // const email = formJson.email;
//             // console.log(email);
//             handleClose();
//           },
//         }}
//       >
//         <DialogTitle>Haus bearbeiten</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             {/* To subscribe to this website, please enter your email address here. We
//             will send updates occasionally. */}
//           </DialogContentText>
//           <TextField
//             autoFocus
//             required
//             margin="dense"
//             id="adresse"
//             name="adresse"
//             label="Adresse - Ort, Straße, Hausnummer"
//             defaultValue={currentAdresse}
//             type="text"
//             fullWidth
//             variant="standard"
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={()=>{handleClose()}}>Abbrechen</Button>
//           <Button type="submit">Ändern</Button>
//         </DialogActions>
//       </Dialog>
//     </React.Fragment>
//   );
// }
