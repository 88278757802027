import * as React from 'react';
import { Box, Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Button, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { getRegistrationCodeForNewUser } from '../../../../BackendFunctions/backend_api_AuthService';
import { sendMailWithRegistrationCode } from '../../../../BackendFunctions/backend_api_AuthService';
import NutzerListe from './UserList';
import { ipaddress } from '../../../../BackendFunctions/ipconfig';
import { getHouses } from '../../../../BackendFunctions/backend_api_HausverwaltungService';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { preConfigUser } from '../../../../BackendFunctions/backend_api_UserService';

import { AuthService, HausverwaltungService } from '../../../../services/openapi';
import { SyntheticEvent } from 'react';
import APIWrapperContext from '../../../../services/APIWrapperContext';
import { WidgetEmailEditor } from '../../../Widgets/EmailEditor/WidgetEmailEditor';
import ConfigureNewUser from './ConfigureNewUser';
import UserCreationContextProvider from './UserCreationContextProvider';







interface Ihaus{
    house:string,
    number:string
    appartments:Ihaus[]
}

export default function NewUser() {
  // const { token, sessionStart } = React.useContext(SecurityContext)
 
  const [code, setCode] = React.useState("");
  const [emailReceiver, setEmailReceiver] = React.useState("");
  const [emailMessage, setEmailMessage] = React.useState("");
  const [showSendMail, setShowSendMail] = React.useState(false);
  const [showPreConfigUser, setShowPreConfigUser] = React.useState(false);
  const [loadUsersUpdateFct, setLoadUsersUpdateFct] = React.useState(() => { });
  const [hauslist, set_hauslist] = React.useState<Ihaus[]>([]);
  const [hauslist_preConfUserAccess, set_hauslist_preConfUserAccess] = React.useState<Ihaus[]>([]);

  const {call_backend_api} = React.useContext(APIWrapperContext)


  

  const addAppartment_PreConfUserAccess = (appartment:Ihaus) => {
    let groupList_tmp = [...hauslist_preConfUserAccess]

    groupList_tmp.push(appartment)
    set_hauslist_preConfUserAccess(groupList_tmp)

  }
  const removeAppartment_PreConfUserAccess = (appartment:Ihaus) => {
    let usersGroups_tmp = [...hauslist_preConfUserAccess]
    usersGroups_tmp = usersGroups_tmp.filter(g => !(g.house == appartment.house && g.number == appartment.number))

    set_hauslist_preConfUserAccess(usersGroups_tmp)
  }


  React.useEffect(() => {
    console.log("Userfct loaded")
    console.log(loadUsersUpdateFct)
  }, [loadUsersUpdateFct])

  React.useEffect(() => {
    const message = `<html>
      Sehr geehrte Frau ... / Sehr geehrter Herr ...,
      <br><br>
      mit dieser E-Mail senden wir Ihnen den Registrierungs-Code für unsere digitale Dokumentenablage.
      <br>
      Bitte verwenden Sie den Code für die Registrierung unter <a href="https://app.boehringer.net/register">app.boehringer.net/register</a>.
      <br>
      Ihr persönlicher Code: ` + code +
      `<br><br>Vielen Grüße,<br><br>ihr Team von Böhringer.
      <html>`;

    setEmailMessage(message)
    setShowSendMail(false)
    setShowPreConfigUser(false)
    setEmailReceiver("")
  }, [code])

  
  interface Ihouse{
    house:string,
    appartmentname:string
  }

  const handleConfigUser = () => {
    let houseList:Ihouse[] = []
    hauslist_preConfUserAccess.forEach(el => {
      houseList.push(
        {
          "house": el.house,
          "appartmentname": el.number
        }
      );

    })
    if (code.length > 0) {
      preConfigUser(code, houseList)
    }

  }

  const getHausliste = () => {
    call_backend_api(HausverwaltungService.listHousesHausverwaltungHousesListGet(),(t:Ihaus[])=>set_hauslist(t),undefined,undefined,false)
    //getHouses().then(t => { set_hauslist(t) })

  }

  const handleSendEmailToReceiver = (event:React.MouseEvent<HTMLElement>) => {
    call_backend_api(AuthService.sendRegistrationMailAuthRegistrationSendRegistrationMailPost({emailReceiver:emailReceiver, message:emailMessage}),()=>{
        setCode("")
      setShowSendMail(false)
      setShowPreConfigUser(false)
      setEmailMessage("")
      setEmailReceiver("")
    },undefined,undefined,true,"E-mail wurde versendet","Fehler beim Senden der Email!")
       
    
  }

  const handleShowSendMailButton = (event:React.MouseEvent<HTMLElement>) => {
    setShowSendMail(true)
  }
  const handleShowPreConfigUserButton = (event:React.MouseEvent<HTMLElement>) => {
    getHausliste()
    setShowPreConfigUser(true)
  }

  const handleEmailReceiverChange = (event:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setEmailReceiver(event.target.value)
  }
  const handleEmailMessageChange = (event:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    console.log(event.target.value)
    setEmailMessage(event.target.value)
  }

  
  interface IGeneratedCode{
    code:string
    status:boolean
  }
  const handleCreateCode = () => {
    call_backend_api(
      AuthService.getNewRegistrationCodeAuthRegistrationGenerateCodeGet(),
      (t:IGeneratedCode)=>setCode(t.code),
      undefined,undefined,true,"Code generiert","Fehler beim Generieren des Codes!")
    
    
  }

  return (
    <>
    
            {/* <Box sx={{ width: '100%' }}>
              <Button variant="outlined" onClick={() => handleCreateCode()}>neuen Code generieren</Button>
              
            </Box>
            {code !== "" && code!==undefined &&
              <Box sx={{ width: '100%', flexDirection: 'column', display: 'flex' }}>
                <TextField id="outlined-basic" label="Code" variant="outlined" value={code} defaultValue={code} sx={{ mt: 1,mb:1 }} />
                {(code.length) &&
                  <Button sx={{ mb: 1 }} variant="contained" disabled={showPreConfigUser} onClick={handleShowPreConfigUserButton} >Nutzer konfigurieren</Button>
                }
                {(code.length && showPreConfigUser) &&
                  <>
                    <Typography>
                      Zugriff auf Folgende Appartments:
                    </Typography>
                    {hauslist_preConfUserAccess.map(el => <Chip

                      label={"Haus:" + el.house + " - Wohnung:" + el.number}
                      onDelete={() => { removeAppartment_PreConfUserAccess(el) }}
                    />)}
                    <Typography>

                      Mögliche Appartments:
                    </Typography>
                    {hauslist.reduce((p:Ihaus[], c) => { c.appartments.forEach((el:Ihaus) => p.push(el)); return p }, []).filter((el) => hauslist_preConfUserAccess.reduce((p, c) => p == false ? p : (!(el.house == c.house && el.number == c.number)), true)).map(el => 
                    <Chip

                      label={"Haus:" + el.house + " - Wohnung:" + el.number}
                      deleteIcon={<AddCircleOutlineIcon />}
                      onDelete={() => { addAppartment_PreConfUserAccess(el) }}
                    />)}
                   
                    <Button sx={{ mb: 1 }} onClick={handleConfigUser} variant="contained" disabled={false}>Konfigurieren</Button>
                  </>
                }

                {(code.length) &&
                  <Button variant="contained" disabled={showSendMail} onClick={handleShowSendMailButton} >E-Mail mit Code erstellen</Button>
                }
                {(code.length && showSendMail) &&
                  <>
                    <WidgetEmailEditor></WidgetEmailEditor>
                    <TextField error={emailReceiver.length ? false : true} id="receiverMail" label="E-Mail Adresse Empfänger" variant="outlined" value={emailReceiver} defaultValue={emailReceiver} sx={{ m: 1 }} onChange={handleEmailReceiverChange} />
                    <TextField multiline id="receiverMailBody" label="Nachricht" variant="outlined" value={emailMessage} defaultValue={emailMessage} sx={{ m: 1 }} onChange={handleEmailMessageChange} />
                    <Button onClick={handleSendEmailToReceiver} variant="contained" disabled={code.length && emailReceiver.length && emailMessage.length ? false : true}>E-Mail abschicken</Button>
                  </>
                }
              </Box>
            } */}
              <Box sx={{ width: '100%', flexDirection: 'column', display: 'flex' }}>
              <UserCreationContextProvider>
                <ConfigureNewUser/>
                </UserCreationContextProvider>
                {/* <Button variant="contained" onClick={() => {}}>Code mit Einstellungen speichern</Button> */}
                </Box>

     

    </>
  );
}





