import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Chip, Paper, Tooltip } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { InfoOutlined } from "@mui/icons-material";

interface Iendpoint_small {
  end_func: string;
  description:string;
}

interface IChipTransferListProps {
  title: string;
  groupList: Iendpoint_small[];
  usersGroups: Iendpoint_small[];
  titleLeft: string;
  titleRight: string;
  open: boolean;
  closeFunction: (
    newValue?: string[] | undefined,
    action?: string | undefined
  ) => void;
  saveActionName?: string;
}

export default function ChipTransferListEndpointSelection({
  title,
  groupList = [],
  usersGroups = [],
  titleLeft = "",
  titleRight = "",
  open = false,
  closeFunction = (newValue, action) => {},
  saveActionName = undefined,
}: IChipTransferListProps) {
  const [groupList_state, setGrouplist_state] = React.useState(groupList);
  const [Usergroups_state, setUsergroups_state] = React.useState(usersGroups);
  const radioGroupRef = React.useRef<HTMLElement>(null);

  const handleSave = () => {
    closeFunction(Usergroups_state.map(el=>el.end_func), saveActionName);
  };
  const handleAbort = () => {
    closeFunction(undefined, undefined);
  };
  React.useEffect(() => {
    console.log("grouplist updated");
  }, [groupList_state]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };
  React.useEffect(() => {
    setUsergroups_state(usersGroups);
    setGrouplist_state(groupList);
  }, [groupList, usersGroups]);

  const addUsergroup = (groupName: Iendpoint_small) => {
    let groupList_tmp = [...groupList_state];
    groupList_tmp = groupList_tmp.filter((g) => g.end_func !== groupName.end_func);
    let usersGroups_tmp = [...Usergroups_state];
    usersGroups_tmp.push(groupName);
    setGrouplist_state(groupList_tmp);
    setUsergroups_state(usersGroups_tmp);
  };
  const removeUsergroup = (groupName: Iendpoint_small) => {
    let usersGroups_tmp = [...Usergroups_state];
    usersGroups_tmp = usersGroups_tmp.filter((g) => g.end_func !== groupName.end_func);
    let groupList_tmp = [...groupList_state];
    groupList_tmp.push(groupName);
    setUsergroups_state(usersGroups_tmp);
    setGrouplist_state(groupList_tmp);
  };

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: '80%' } }}
      maxWidth="md"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <h4>{titleLeft}</h4>
        <Paper
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            listStyle: "none",
            p: 0.5,
            m: 0,
          }}
          component="ul"
        >
          {Usergroups_state.sort((a,b)=>Number(a.end_func>=b.end_func)).map((g) => {
            return (
              
              <ListItem key={g.end_func}>
                <Chip
                  label={g.end_func}
                  icon={
                    <Tooltip title={g.description==""?"Keine Beschreibung hinterlegt":g.description} placement={"right"}>
                  <InfoOutlined />
                </Tooltip>
                }
                  onDelete={() => {
                    console.log("Delete", g);
                    removeUsergroup(g);
                  }}
                  />
              </ListItem>
            );
          })}
        </Paper>
        <h4>{titleRight}</h4>
        <Paper
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            listStyle: "none",
            p: 0.5,
            m: 0,
          }}
          component="ul"
        >
          {groupList_state.sort((a,b)=>Number(a.end_func>=b.end_func)).map((g) => {
            return (
              <ListItem key={g.end_func}>
                <Chip
                  label={g.end_func}
                  icon={
                    <Tooltip title={g.description==""?"Keine Beschreibung hinterlegt":g.description} placement={"right"}>
                  <InfoOutlined />
                  </Tooltip>
                }
                  deleteIcon={<AddCircleOutlineIcon  />}
                  onDelete={() => {
                    addUsergroup(g);
                  }}
                />
              </ListItem>
            );

          })}
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleSave} variant="contained">
          Speichern
        </Button>
        <Button onClick={handleAbort} variant="outlined">
          Abbrechen
        </Button>
      </DialogActions>
    </Dialog>
  );
}
