import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CloseIcon from '@mui/icons-material/Close';


import UserContext from '../../Contexts/UserContext/UserContext';

import { useNavigate } from "react-router-dom";


import AppBarContext from '../../Contexts/AppBarContext/AppBarContext';
import { Avatar } from '@mui/material';
import path from 'path';

interface INavigationLink {
    name: string,
    link: string,
    icon: React.ReactNode,
}
interface IDrawerLeftProps {
    navigationLinks_Standard?: INavigationLink[],
    navigationLinks_Admin?: INavigationLink[],
    navigationLinks_Userspecific?: INavigationLink[],
    navigationLinks_Special?: INavigationLink[],
    navigationLinks_DeleteFolder?: INavigationLink[],
}

export default function DrawerLeft({navigationLinks_Standard=[],navigationLinks_Admin=[],navigationLinks_Userspecific=[], navigationLinks_Special=[],navigationLinks_DeleteFolder=[]}:IDrawerLeftProps) {
  const theme = useTheme();
  const {openDrawer,setOpenDrawer}= React.useContext(AppBarContext)
  const { user_roll, email } = React.useContext(UserContext)
  const [pathname,setPathname] = React.useState(window.location.pathname)
  const [hashedMail,setHashedMail] = React.useState("")
  React.useEffect(() => {
    setPathname(window.location.pathname)
  
  }, [window.location.pathname])
  
  const drawerWidth = 340;
  
  
  
  
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    //justifyContent: 'flex-end',
  }));
  

  

  let navigate = useNavigate();

 

  const handleDrawerClose = () => {
    // setOpen(true);
    setOpenDrawer(false);
  };



  
  
  async function sha256(str:string) {
    const encoder = new TextEncoder();
    const encdata = encoder.encode(str);
    const buf = await crypto.subtle.digest("SHA-256", encdata);
    return Array.prototype.map.call(new Uint8Array(buf), x=>(('00'+x.toString(16)).slice(-2))).join('');
  }
  React.useEffect(() => {
    if (email)
    sha256(email).then((res:string)=>{setHashedMail(res)})
    else setHashedMail("")
  }, [email])
  

  
  

  return (
    // <ThemeProvider theme={theme.palette.main}>
      <>
    
  
      

      <Drawer
        ModalProps={{
          keepMounted: true,
      }}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        // variant="persistent"
        anchor="left"
        open={openDrawer}
      >
        <DrawerHeader>
          
        <Avatar alt="Logo" src="/logoboe.svg" variant="square" sx={{ height: '70px', width: '70px', marginRight: "24px" }} />
          <Box sx={{flex:1}}></Box>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <CloseIcon/> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List sx={{
          // // selected and (selected + hover) states
          // '&& .Mui-selected, && .Mui-selected:hover': {
          //   bgcolor: 'red',
          //   '&, & .MuiListItemIcon-root': {
          //     color: 'pink',
          //   },
          // },
          // // hover states
          // '& .css-asvkav-MuiButtonBase-root-MuiListItem-root:hover': {
          //   bgcolor: 'blue',//'primary.main',
          //   '&, & .MuiListItemIcon-root': {
          //     color: 'red',//'primary.contrastText',
          //   },
          // },
        }}>
          {navigationLinks_Standard.map((item, index) => (
            <ListItem sx={{cursor:'pointer',bgcolor:pathname===item.link?"rgb(211 226 241)":"inherit",":hover":{backgroundColor:"rgb(211 226 241)"}}}  key={item.name + index} onClick={() => { navigate(item.link); handleDrawerClose() }}>
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItem>
          ))}
        </List>
        <Divider />
        
        {(user_roll && (user_roll.includes("Administrator")||user_roll.includes("Admin Bau")||user_roll.includes("Admin Hausverwaltung"))) &&
          // ["super_admin", "Administrator", "Hausverwaltung"].includes(user_roll))
          // &&
          <>
            <List sx={{
              // // selected and (selected + hover) states
              // '&& .Mui-selected, && .Mui-selected:hover': {
              //   bgcolor: 'red',
              //   '&, & .MuiListItemIcon-root': {
              //     color: 'pink',
              //   },
              // },
              // // hover states
              // '& .css-asvkav-MuiButtonBase-root-MuiListItem-root:hover': {
              //   bgcolor: 'primary.main',
              //   '&, & .MuiListItemIcon-root': {
              //     color: 'primary.contrastText',
              //   },
              // },
            }}>
              {

                navigationLinks_Admin.map((item, index) => (
                  <ListItem sx={{cursor:'pointer',bgcolor:pathname===item.link?"rgb(211 226 241)":"inherit",":hover":{backgroundColor:"rgb(211 226 241)"}}} key={item.name + index} onClick={() => { navigate(item.link); handleDrawerClose() }}>
                    <ListItemIcon sx={{}}>
                      {/* {index % 2 === 1 ? <InboxIcon /> : <HomeIcon />} */}
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.name} sx={{}} />
                  </ListItem>
                ))}

            </List>
            <Divider />
          </>
        }
        {(user_roll && (hashedMail!=="") && (user_roll.includes("Administrator"))) && (hashedMail==="a4c68f1521298dd5ea570eb762e352bb10a6d5600d9ce52d2e6e80bb312725da") &&
          
          <>
            <List sx={{
              
            }}>
              {

                navigationLinks_Special.map((item, index) => (
                  <ListItem sx={{cursor:'pointer',bgcolor:pathname===item.link?"rgb(211 226 241)":"inherit",":hover":{backgroundColor:"rgb(211 226 241)"}}} key={item.name + index} onClick={() => { navigate(item.link); handleDrawerClose() }}>
                    <ListItemIcon sx={{}}>
                      {/* {index % 2 === 1 ? <InboxIcon /> : <HomeIcon />} */}
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.name} sx={{}} />
                  </ListItem>
                ))}

            </List>
            <Divider />
          </>
        }
        {(user_roll && user_roll.includes("Administrator"))&&
          
          <>
            <List sx={{
              
            }}>
              {

                navigationLinks_DeleteFolder.map((item, index) => (
                  <ListItem sx={{cursor:'pointer',bgcolor:pathname===item.link?"rgb(211 226 241)":"inherit",":hover":{backgroundColor:"rgb(211 226 241)"}}} key={item.name + index} onClick={() => { navigate(item.link); handleDrawerClose() }}>
                    <ListItemIcon sx={{}}>
                      {/* {index % 2 === 1 ? <InboxIcon /> : <HomeIcon />} */}
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.name} sx={{}} />
                  </ListItem>
                ))}

            </List>
            <Divider />
          </>
        }
        <List sx={{
          // selected and (selected + hover) states

          // hover states
          // '& .css-asvkav-MuiButtonBase-root-MuiListItem-root:hover': {
          //   bgcolor: 'primary.main',

          //   '&, & .MuiListItemIcon-root': {
          //     color: 'primary.contrastText',
          //   },
          // },
        }}>
          {navigationLinks_Userspecific.map((item, index) => (
            <ListItem sx={{cursor:'pointer',bgcolor:pathname===item.link?"rgb(211 226 241)":"inherit",":hover":{backgroundColor:"rgb(211 226 241)"}}} key={item.name + index} onClick={() => { navigate(item.link); handleDrawerClose() }}>
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      
    
        </>
    // </ThemeProvider>
  );
}