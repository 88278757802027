import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Button, Card, CardMedia, Checkbox, IconButton, Link, MobileStepper, TableHead, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { AddAPhoto, Edit, PlaylistAdd, PlaylistAddCircle } from '@mui/icons-material';
import EditAd from './EditAdvertisement';
import { AdsService, OpenAPI } from '../../../services/openapi';
import APIWrapperContext from '../../../services/APIWrapperContext';

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
      event: React.MouseEvent<HTMLButtonElement>,
      newPage: number,
    ) => void;
  }
  
  function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>,
    ) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  export interface IAdvertisement{
    id: number,
    link: string,
    img_url: string,
    description: string,
    location: string,
    status: string,
    visible:boolean
  }

  export interface ICustomPaginationActionsTable{
    rows:IAdvertisement[]
  }


  export function CustomPaginationActionsTable(props:ICustomPaginationActionsTable) {
    const {rows} =props
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [selectedAd,setSelectedAd] = React.useState<IAdvertisement|undefined>(undefined)
    const [openEditAd,setOpenEditAd] = React.useState<boolean>(false)
    const [tmpRows,setTmpRows] = React.useState<IAdvertisement[]>(rows)
    React.useEffect(()=>{
        setTmpRows(rows)
        console.log("rows reset")
    },[rows])
 const {call_backend_api}=React.useContext(APIWrapperContext)
    const handleAddNewAd = ()=>{
        let newAd:IAdvertisement = {
            id: -1,
            link: "",
            img_url: "",
            description: "",
            location: "",
            status: "",
            visible:true
        }
        setTmpRows([...tmpRows,newAd])
        setSelectedAd(newAd)
        setOpenEditAd(true)
    }

    const handleEditAd = (ad:IAdvertisement)=>{
        setSelectedAd(ad)
        setOpenEditAd(true)
    }
    const handleEditAd_Closed = (ad:IAdvertisement|undefined,file:Blob|undefined)=>{
        if(ad!==undefined){
            if(ad.id===-1 && file!==undefined){
                console.log("hochgeladene Datei",file)
                call_backend_api(AdsService.addNewAdAdsNewAdPost(ad.link,"undefined",ad.description,ad.location,ad.status,ad.visible,{image:new Blob([file])},ad.id),undefined,undefined,undefined,true)

            }

            console.log(ad)
            const index = tmpRows.findIndex((item)=>item.id===ad.id)
            if(index!==-1){
                call_backend_api(AdsService.updateAdsAdsUpdateAdPut(ad.link,tmpRows[index].img_url,ad.description, ad.location,ad.status,ad.visible,ad.id,file?{image:new Blob([file])}:undefined),undefined,undefined,undefined,true)
                tmpRows[index]=ad
                setTmpRows([...tmpRows])
            }
        }
        setSelectedAd(undefined)
        setOpenEditAd(false)
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tmpRows.length) : 0;
  
    const handleChangePage = (
      event: React.MouseEvent<HTMLButtonElement> | null,
      newPage: number,
    ) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
  
    return (
        <Box sx={{maxWidth:"100%",flex:1,display:'flex',flexDirection:'column'}}>
      <TableContainer component={Paper} sx={{flex:1,overflowX:'auto'}}>
        <Table stickyHeader  sx={{  }} aria-label="custom pagination table">
        
        <TableHead>
        <TableRow sx={{}}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={8}
                count={tmpRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                sx={{flex:1}}
              />
            </TableRow>
          <TableRow>

            <TableCell sx={{fontWeight:'bold'}}>
                <IconButton onClick={()=>handleAddNewAd()} >
                    <PlaylistAdd />
                </IconButton>
            </TableCell>
            <TableCell sx={{fontWeight:'bold'}}>ID</TableCell>
            <TableCell sx={{fontWeight:'bold'}}>Sichtbar</TableCell>
            <TableCell sx={{fontWeight:'bold'}}>Name</TableCell>
            <TableCell sx={{fontWeight:'bold'}}>Ortschaft</TableCell>
            <TableCell sx={{fontWeight:'bold'}}>Status</TableCell>
            <TableCell sx={{fontWeight:'bold'}}>Info-Link</TableCell>
            <TableCell sx={{fontWeight:'bold'}} align='center'>Bild</TableCell>
          </TableRow>
        </TableHead>

          <TableBody>
            {(rowsPerPage > 0
              ? tmpRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : tmpRows
            ).map((row) => (
              <TableRow key={row.id} sx={{}}>
                <TableCell style={{  }} align="left">
                  <IconButton onClick={()=>handleEditAd(row)}>
                    <Edit></Edit>
                  </IconButton>
                </TableCell>
                <TableCell sx={{}} >
                  {row.id}
                </TableCell>
                <TableCell sx={{}} >
                <Checkbox checked={row.visible}  disabled />
                </TableCell>
                <TableCell sx={{flex:1}} align="left">
                  {row.description}
                </TableCell>
                <TableCell sx={{flex:1}} align="left">
                  {row.location}
                </TableCell>
                <TableCell sx={{flex:1}} align="center">
                  {row.status}
                </TableCell>
                <TableCell style={{ whiteSpace:'break-spaces',flex:1 }} align="left">
                  <Link href={row.link} >{row.link}</Link>
                </TableCell>
                <TableCell style={{  }} align="center">
                  {/* {row.img_url} */}
                  <Box
                                component="img"
                                sx={{ flex: 1, objectFit: 'contain', height: '100%', maxHeight:"200px", }}
                                src={"" + OpenAPI.BASE + "/ads/image?image_id="+row.img_url}
                                alt="Live from space album cover"
                            />
                </TableCell>
                
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          
        </Table>
        <EditAd open={openEditAd} setOpen={handleEditAd_Closed} ad={selectedAd}></EditAd>
      </TableContainer>
      </Box>
    );
  }