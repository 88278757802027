import * as React from "react";
import { useTheme } from "@mui/material/styles";
import {
  Button,
  Card,
  CardMedia,
  IconButton,
  MobileStepper,
  Skeleton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { IAdvertisement } from "./BasicTableWithPagination";
import { AdsService } from "../../../services/openapi/services/AdsService";
import APIWrapperContext from "../../../services/APIWrapperContext";
import { OpenAPI } from "../../../services/openapi";
import { inherits } from "util";
import { ChevronLeftRounded, ChevronRightRounded } from "@mui/icons-material";

interface ILoadedImages {
  [key: string]: boolean;
}
interface IImages {
  [key: string]: string;
}

export default function Ads() {
  const theme = useTheme();
  const { call_backend_api } = React.useContext(APIWrapperContext);
  const [galaryMoverTimer, setGalaryMoverTimer] = React.useState<
    NodeJS.Timer | undefined
  >(undefined);
  const [loadingAds, setLoadingAds] = React.useState(false);
  function createData(data: IAdvertisement) {
    return data;
  }
  //const rows = Haeuser.map((el,k)=>createData({...el,id:k} )).sort((a, b) => (a.id < b.id ? -1 : 1));
  const [rows, setRows] = React.useState<IAdvertisement[]>([]);
  const [adImages, setAdImages] = React.useState<IImages>({});
  React.useEffect(() => {
    setLoadingAds(true);
    call_backend_api(
      AdsService.getAllAdsVisibleAdsAdsListVisibleGet(),
      (t: any) => {
        setRows([
          ...t
            .map((el: any, k: number) => createData({ ...el, id: k }))
            .filter((a: IAdvertisement) => a.visible)
            .sort((a: IAdvertisement, b: IAdvertisement) =>
              a.id < b.id ? -1 : 1
            ),
        ]);
        setLoadingAds(false);
      },
      undefined,
      undefined,
      false,
      "Werbung geladen",
      "Fehler beim Laden."
    );
  }, []);
  // const add_adImage = React.useCallback((imgUrl:string,objectURL:string)=>{
  //   console.log("curr Elements",adImages);

  // },[adImages]);
  React.useEffect(() => {
    if (rows.length > 0) {
      rows.forEach((el: IAdvertisement) => {
        console.log(
          "fetching",
          OpenAPI.BASE + "/ads/image?image_id=" + el.img_url
        );
        fetch(OpenAPI.BASE + "/ads/image?image_id=" + el.img_url)
          .then(function (response) {
            return response.blob();
          })
          .then(function (myBlob) {
            var objectURL = URL.createObjectURL(myBlob);
            let updateObj = { [el.img_url]: objectURL };
            setAdImages((adImages) => ({ ...adImages, ...updateObj }));
          });
      });
    }
  }, [rows]);

  React.useEffect(() => {
    console.log("adImages", adImages);
  }, [adImages]);

  const Haeuser = [
    {
      link: "https://www.boehringer.net/haeuser/details/goldammerstr-8-brackenheim/",
      img: "boe_goldammer_hp-beschnitt.jpg",
      description: "Goldammerstraße 8, 8/1, 12-12/5",
      location: "Brackenheim",
      status: "im Bau",
    },
    {
      link: "https://www.boehringer.net/wohnungen/eigentumswohnungen/details/allee-82-heilbronn/",
      img: "illu_allee_ansicht_nordwest__1.jpg",
      description: "Otthello - Das neue Stadthaus am Theater",
      location: "Heilbronn",
      status: "im Bau",
    },
    {
      link: "https://www.boehringer.net/wohnungen/eigentumswohnungen/details/sontheimer-str-38-42-besigheimer-str-47-heilbronn/",
      img: "boe_sontheim_illu_d1-n2_ohne_s._l_.jpg",
      description: "Sontheim Str. 38-42, Besigheimer Str.47",
      location: "Heilbronn",
      status: "im Bau",
    },
    {
      link: "https://www.boehringer.net/wohnungen/eigentumswohnungen/details/hauffstr-19-1-schwaigern/",
      img: "illu.jpg",
      description: "Hauffstr. 19/1",
      location: "Schwaigern",
      status: "im Bau",
    },
    {
      link: "https://www.boehringer.net/wohnungen/eigentumswohnungen/details/lindengasse-10-flein/",
      img: "flein_visualisierung_01_3.jpg",
      description: "Lindengasse 10+10/1",
      location: "Flein",
      status: "im Bau",
    },
    {
      link: "https://www.boehringer.net/wohnungen/eigentumswohnungen/details/lichtenberger-str-8-oberstenfeld/",
      img: "boe_oberstenfeld_hp-beschnitt.jpg",
      description: "Lichtenberger Str. 8",
      location: "Oberstenfeld",
      status: "im Bau",
    },
  ];

  const [activeStep, setActiveStep] = React.useState(0);
  const [loadedImages, setLoadedImages] = React.useState<ILoadedImages>({});
  const handleNext = () => {
    galaryMoverTimer && clearInterval(galaryMoverTimer);
    setActiveStep((prevActiveStep) =>
      prevActiveStep + 1 >= rows.length ? 0 : prevActiveStep + 1
    );
  };

  const handleBack = () => {
    galaryMoverTimer && clearInterval(galaryMoverTimer);
    setActiveStep((prevActiveStep) =>
      prevActiveStep - 1 < 0 ? rows.length - 1 : prevActiveStep - 1
    );
  };

  React.useEffect(() => {
    if (activeStep >= rows.filter((el) => el.img_url in adImages).length) {
      setActiveStep(0);
    }
  }, [activeStep, rows, adImages]);

  const moveGallery = () => {
    console.log(activeStep);
    if (activeStep > rows.filter((el) => el.img_url in adImages).length) {
      setActiveStep(0);
    } else {
      setActiveStep((prevActiveStep) =>
        prevActiveStep + 1 >= rows.filter((el) => el.img_url in adImages).length
          ? 0
          : prevActiveStep + 1
      );
    }
  };
  React.useEffect(() => {
    const id = setInterval(() => moveGallery(), 5000);
    setGalaryMoverTimer(id);

    return () => clearInterval(id);
  }, [rows]);

  const matchesMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      {(loadingAds || rows.length!==Object.keys(adImages).length || false) && (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            flex: 1,
            justifyContent: "space-between",
            gap: { md: 10, xs: 2 },
          }}
        >
          {(matchesMd?[0, 1]:[0]).map((el) => (
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                //   minWidth: { xs: "100%", md: 200 },
                flex: 1,
                maxHeight: { xs: 200, md: "inherit" },
                height: "100%",
                width: "100%",
              }}
            >
              <Box sx={{ flex: 1, display: "flex", width: "100%" }}>
                <Skeleton
                  variant="rounded"
                  sx={{
                    flex: 1,
                    width: "100%",
                    maxHeight: 185,
                    height: 200,
                  }}
                />
              </Box>
              <Box sx={{ minHeight: 72, mt:1,mb:1 }}>
                <Typography variant="body1">
                  <Skeleton variant="text" width={100} />
                </Typography>
                <Typography variant="body1">
                  <Skeleton variant="text" width={70} />
                </Typography>
                <Typography variant="body1">
                  <Skeleton variant="text" width={90} />
                </Typography>
              </Box>
            </Card>
          ))}
        </Box>
      )}

      {((!loadingAds&& rows.length===Object.keys(adImages).length) || false) && (
        <Box
          sx={{
            width: "100%",
            height: { xs: "inherit", md: "100%" },
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            flex: 1,
            justifyContent: "space-between",

            gap: { md: 10, xs: 2 },
          }}
        >
          {(matchesMd &&
          rows.filter((el) => el.img_url in adImages).length > 0 &&
          rows.filter((el) => el.img_url in adImages).length > 1
            ? [
                rows.filter((el) => el.img_url in adImages)[activeStep],
                rows.filter((el) => el.img_url in adImages)[
                  activeStep + 1 >=
                  rows.filter((el) => el.img_url in adImages).length
                    ? 0
                    : activeStep + 1
                ],
              ]
            : rows.filter((el) => el.img_url in adImages)
          ).map((el) => (
            <IconButton
              sx={{ p: 0, fontSize: "inherit", flex: 1 }}
              href={el.link}
              target="_blank"
            >
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  //   minWidth: { xs: "100%", md: 200 },
                  flex: 1,
                  maxHeight: { xs: 200, md: 200 },
                  height: "100%",
                  width: "100%",
                }}
              >
                <Box sx={{ flex: 1, display: "flex" }}>
                  {!adImages[el.img_url] && (
                    <Skeleton
                      variant="rounded"
                      sx={{
                        flex: 1,
                        width: "100%",
                        maxHeight: 185,
                        height: !adImages[el.img_url] ? 200 : 0,
                      }}
                    />
                  )}

                  <CardMedia
                    component="img"
                    sx={{
                      flex: 1,
                      objectFit: "contain",
                      height: adImages[el.img_url] ? "100%" : 0,
                      maxHeight: { xs: 200 - 60, md: 200-60 },
                    }}
                    // onLoad={() => {
                    //   setLoadedImages({
                    //     ...loadedImages,
                    //     [el.img_url]: true,
                    //   });
                    // }}
                    // sx={{ flex: 1, objectFit: 'cover', height: '100%' }}
                    image={
                      adImages[el.img_url]
                      // "" + OpenAPI.BASE + "/ads/image?image_id=" + el.img_url
                    }
                    alt={el.description}
                  />
                </Box>
                <Box sx={{ minHeight: 57, height:72,mt:1,mb:1 }}>
                <Typography paragraph sx={{fontSize:15,p:0,m:0, lineHeight:1.2}}>
                    <b>{el.location}</b>
                  </Typography>
                  <Typography paragraph sx={{fontSize:15,p:0,m:0,lineHeight:1.2}}>{el.description}</Typography>
                  <Typography paragraph sx={{fontSize:15,p:0,m:0,lineHeight:1.2}}>{el.status}</Typography>
                  {/* <p style={{ margin: 0, padding: 0 }}>{el.description}</p>
                  <p style={{ margin: 0, padding: 0 }}>{el.status}</p> */}
                </Box>
              </Card>
            </IconButton>
          ))}
          {/* {!matchesMd &&
            rows.filter(el=>el.img_url in adImages).length > 0 &&
            rows.filter(el=>el.img_url in adImages).map((el) => (
              <IconButton
                sx={{ p: 0, fontSize: "inherit" }}
                href={el.link}
                target="_blank"
              >
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    //   minWidth: { xs: "100%", md: 200 },
                    flex: 1,
                    maxHeight: { xs: 200, md: "inherit" },
                  }}
                >
                  <Box sx={{ flex: 1, display: "flex" }}>
                    <Skeleton
                      variant="rounded"
                      sx={{
                        flex: 1,
                        width: "100%",
                        maxHeight: 185,
                        height: !adImages[el.img_url] ? 200 : 0,
                      }}
                    />
                    {String(adImages[el.img_url])}
                    <CardMedia
                      component="img"
                      sx={{
                        flex: 1,
                        objectFit: "contain",
                        height: adImages[el.img_url] ? "100%" : 0,
                        maxHeight: 200 - 60,
                      }}
                      // onLoad={() => {
                      //   setLoadedImages({
                      //     ...loadedImages,
                      //     [el.img_url]: true,
                      //   });
                      // }}
                      image={
                        // "" + OpenAPI.BASE + "/ads/image?image_id=" + el.img_url
                        adImages[el.img_url]
                      }
                      alt={el.description}
                    />
                  </Box>
                  <Box sx={{}}>
                    <p style={{ margin: 0, padding: 0 }}>
                      <b>{el.location}</b>
                    </p>

                    <p style={{ margin: 0, padding: 0 }}>{el.description}</p>
                    <p style={{ margin: 0, marginBottom: 10, padding: 0 }}>
                      {el.status}
                    </p>
                  </Box>
                </Card>
              </IconButton>
            ))*/}
        </Box>
      )}

      <Box>
        {matchesMd &&
          rows.filter((el) => el.img_url in adImages).length == 0 && (
            <Skeleton variant="rounded" width={400} height={30} sx={{ m: 3 }} />
          )}
        {matchesMd &&
          rows.filter((el) => el.img_url in adImages).length > 0 && (
            <MobileStepper
              variant="dots"
              steps={rows.filter((el) => el.img_url in adImages).length}
              position="static"
              activeStep={activeStep}
              sx={{ maxWidth: 400, mt: 1 }}
              nextButton={
                <IconButton
                  size="small"
                  onClick={handleNext}
                  disabled={
                    activeStep ===
                    rows.filter((el) => el.img_url in adImages).length
                  }
                >
                  <ChevronRightRounded />
                  {/* {">"} */}
                  {theme.direction === "rtl" ? <></> : <></>}
                </IconButton>
              }
              backButton={
                <IconButton
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === -1}
                >
                  {theme.direction === "rtl" ? <></> : <></>}
                  <ChevronLeftRounded />
                  {/* {"<"} */}
                </IconButton>
              }
            />
          )}
      </Box>
    </>
    // </ThemeProvider>
  );
}
